export const testMode = false;

let finalChainId = 56;
let finalPresaleAddress = '0x81889a8299E66EeaeEAcd0eeCd8d9A7d709639e9'
let finalProvider =  "https://bsc-dataseed.binance.org";
if (testMode) {
    finalPresaleAddress = '0x42E365F1160734bD061C3d5B7EA3C658aF7C256A'
    finalProvider = "https://data-seed-prebsc-1-s1.binance.org:8545/";
    finalChainId = 97;
}

export const provider = finalProvider;
export const presaleAddress = finalPresaleAddress;
export const chainId = finalChainId;