import BigNumber from 'bignumber.js'
import { ethers } from 'ethers'

BigNumber.config({
  EXPONENTIAL_AT: 1000,
  DECIMAL_PLACES: 80,
})

export const getDepositAmount = async (presale) => {
  return new BigNumber(await presale.presale.contracts.PresaleErc20.methods.getDepositAmount().call())
}

export const deposit = async (contract,account, amount) => {
  return contract.presale.contracts.PresaleErc20.methods
      .deposit()
      .send({ from: account , value: new BigNumber(amount).times(new BigNumber(10).pow(18)).toString()})
      .on('transactionHash', (tx) => {
        return tx.transactionHash
      })
}

export const release = async (contract, account) => {
  return contract.presale.contracts.PresaleErc20.methods
      .releaseFunds()
      .send({ from: account })
      .on('transactionHash', (tx) => {
        return tx.transactionHash
      })
}


export const approveAddress = async (lpContract, address, account) => {
  return lpContract.methods
      .approve(address, ethers.constants.MaxUint256)
      .send({ from: account })
}

