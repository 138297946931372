import React, { useState } from 'react'
import Page from '../../components/Page'
import ERC20ABI from '../../presale/lib/abi/presaleErc20.json'
import * as bsc from 'use-wallet'
import Web3 from 'web3'
import PageHeader from '../../components/PageHeader'
import { useMediaQuery } from 'react-responsive'
import mainImg from '../../assets/img/logo-lg.png'
import { presaleAddress, provider, testMode } from '../../constants/tokenAddresses'
import { AbiItem } from 'web3-utils'
import { Snackbar } from '@material-ui/core';
import Button from '../../components/Button'
import usePresale from '../../hooks/usePresale'
import { release } from '../../presale/utils'
import { getBalanceNumber, getDisplayBalance } from '../../utils/formatBalance'
import BigNumber from 'bignumber.js'
import { DataGrid } from '@material-ui/data-grid';
import { Link } from 'react-router-dom'

let synced = false;
let allDeposits: any = [];

let prefix = "";
if (testMode) {
  prefix = "testnet.";
}

const columns = [
  { field: 'id', headerName: 'ID', width: 90 },
  { field: 'address', headerName: 'Wallet', width: 300 },
  {
    field: 'amount', headerName: 'Amount', width: 130, valueFormatter: (params: { value: any }) => {
      return `${params.value} BNB`;
    },
  },
  {
    field: 'tokens', headerName: 'Tokens', width: 130, valueFormatter: (params: { value: any }) => {
      return `${params.value} Bullets`;
    },
  },
  {
    field: 'hash', headerName: 'Hash', width: 230, renderCell: (params: { value: any }) => {
      return <Link target={"_blank"} to={{pathname: "https://"+prefix+"bscscan.com/tx/" + params.value}}>{params.value}</Link>;
    },
  },
];

const Admin: React.FC = () => {
  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 768px)'
  })

  const presale = usePresale();

  const releaseFunds = () => {
    release(presale, account)
  };


  const web3 = new Web3(new Web3.providers.HttpProvider(provider));
  const presaleContract = new web3.eth.Contract((ERC20ABI as unknown) as AbiItem, presaleAddress);

  const { account }: { account: any } = bsc.useWallet()

  const wallet = bsc.useWallet()

  const [owner, setOwner] = useState('')
  const getOwner = async () => {
    const result = await presaleContract.methods.owner().call();
    setOwner(result)
  }
  getOwner()

  const [tokensForEth, setTokensForEth] = useState(0)
  const getTokensForEth = async () => {
    const result = await presaleContract.methods.tokensForEth().call();
    setTokensForEth(new BigNumber(result).toNumber() / 1E18)
  }
  getTokensForEth()
  

  let open = false;

  let message = 'Not connected';

  if (owner !== wallet.account) {
    open = true;
    message = 'Not Owner!';
  }

  if (!wallet.account) {
    message = 'Not connected';
  }



  const [deposits, setDeposits] = useState([])
  const getDeposits = async () => {
    let promises: any = [];

    const processEvents = (result: any) => {
      if (result.length) {
        result.map((v: any) => {
          allDeposits.push({
            id: v.id,
            address: v.returnValues.user,
            amount: getDisplayBalance(new BigNumber(v.returnValues.amount)),
            tokens: tokensForEth * getBalanceNumber(new BigNumber(v.returnValues.amount)),
            hash: v.transactionHash
          });
        });
      }
    }

    if (owner === wallet.account) {
      const current = await web3.eth.getBlockNumber() + 1;
      if (!synced) {
        // 30k/day blocks
        let i = 0;
        let batchSize = 5000;
        while (i < 80000) {
          promises.push(presaleContract.getPastEvents('Deposited', { fromBlock: current - i - batchSize, toBlock: current - i }).then(processEvents));

          i += batchSize;
        }
        synced = true;
      } else {
        // presaleContract.getPastEvents('Deposited', { fromBlock: current - 5000, toBlock: current}).then(processEvents);
      }
      await Promise.all(promises);

      if (allDeposits.length) {
        setDeposits(allDeposits);
      }
    }
  }
  getDeposits()

  return (
    <div>
      <Page>
        <div style={{ display: isDesktopOrLaptop ? "grid" : "block", gridTemplateColumns: "repeat(3, 1fr)", gridGap: 20 }}>
          <div></div>
          <PageHeader
            icon={<img style={{ width: "100%" }} alt="Logo" src={mainImg} />}
            maintitle="Bullet Token - Admin area"
            title="private sale"
          />
        </div>

        <div style={{ width: '100%', maxWidth: '1200px', padding: '0 24px' }}>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <small style={{ width: '30%' }}>Your Wallet: <b>{wallet.account ?? 'not connected'}</b></small> <br /> <br />
            <div style={{ width: '40%', display: 'flex' }}>
              <Button disabled={owner !== wallet.account} text="Release Funds" onClick={releaseFunds} variant="secondary" />
            </div>
          </div>
          <Snackbar
            open={open}
            autoHideDuration={6000}
            message={message}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right'
            }}
          ></Snackbar>


          <div style={{ height: 400, width: '100%' }}>
            <DataGrid
              rows={deposits}
              columns={columns}
              pageSize={10}
              rowsPerPageOptions={[10]}
            />
          </div>
        </div>
      </Page>
    </div>
  )
}

export default Admin
