import React, { useState } from 'react'
import styled from 'styled-components'
import Button from '../../components/Button'
import Page from '../../components/Page'
import PageHeader from '../../components/PageHeader'
import Balances from './components/Balances'
import { Input } from '@material-ui/core';
import * as bsc from 'use-wallet'
import BigNumber from 'bignumber.js'
import usePresale from '../../hooks/usePresale'
import { deposit } from '../../presale/utils'
import { useMediaQuery } from 'react-responsive'
import Value from '../../components/Value'
import Countdown from 'react-countdown'
import ERC20ABI from '../../presale/lib/abi/presaleErc20.json'
import Web3 from 'web3'
import { AbiItem } from 'web3-utils'
import Binance from 'binance-api-node'
import mainImg from '../../assets/img/logo-lg.png'
import { presaleAddress, provider } from '../../constants/tokenAddresses'

const binance = Binance()
const Home: React.FC = () => {
  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 768px)'
  })

  const [tokenPrice, setNum] = useState(0)

  const getBnbPrice = async () => {
    let ticker = await binance.prices({ symbol: 'BNBUSDT' })
    let price = Number(ticker['BNBUSDT'])
    setNum(price / 1)
  }
  getBnbPrice()

  const { account }: { account: any } = bsc.useWallet()

  const wallet = bsc.useWallet()

  let description = <div style={{ textAlign: 'center', fontSize: '26px', fontFamily: 'Optima', color: 'black', lineHeight: '48px', fontWeight: 'bold' }}>
    <span>Join The Private Sale</span>
  </div>;

  const [leftTime, setCountTime] = useState(0)

  const web3 = new Web3(new Web3.providers.HttpProvider(provider));
  const presaleContract = new web3.eth.Contract((ERC20ABI as unknown) as AbiItem, presaleAddress);

  const getLeftTime = async () => {
    const leftTimeNum = await presaleContract.methods.getLeftTimeAmount().call();
    setCountTime(new BigNumber(leftTimeNum).toNumber() * 1000)
  }
  getLeftTime()

  const [whitelisted, setWhitelisted] = useState(false)
  const getWhitelisted = async () => {
    if (wallet.account) {
      const result: boolean = await presaleContract.methods.getWhitelist(wallet.account).call();
      setWhitelisted(result)
    }
  }
  getWhitelisted()

  const [wlEnabled, setWlEnabled] = useState(false)
  const getWlEnabled = async () => {
    const result: boolean = await presaleContract.methods.whitelistEnabled().call();
    setWlEnabled(result)
  }
  getWlEnabled()


  const [minWallet, setMinWallet] = useState(0)
  const getMinWallet = async () => {
    const result = await presaleContract.methods.minimumDepositEthAmount().call();
    setMinWallet(new BigNumber(result).toNumber() / 1E18)
  }
  getMinWallet()

  const [maxWallet, setMaxWallet] = useState(0)
  const getMaxWallet = async () => {
    const result = await presaleContract.methods.maximumDepositEthAmount().call();
    setMaxWallet(new BigNumber(result).toNumber() / 1E18)
  }
  getMaxWallet()

  const [tokensForEth, setTokensForEth] = useState(0)
  const getTokensForEth = async () => {
    const result = await presaleContract.methods.tokensForEth().call();
    setTokensForEth(new BigNumber(result).toNumber() / 1E18)
  }
  getTokensForEth()

  const [bid, setBid] = useState(0)
  const getBid = async () => {
    if (wallet.account) {
      const result = await presaleContract.methods.deposits(wallet.account).call();
      setBid(new BigNumber(result).toNumber() / 1E18)
    }
  }
  getBid()

  const [depositInput, setDepositNum] = useState(0)

  const depositInputChange = (e: any) => {
    let depositVal = e.target.value;
    setDepositNum(depositVal)
  }

  const presale = usePresale();

  const depositEther = () => {
    deposit(presale, account, depositInput)
  };

  return (
    <div>
      <Page>
        <div style={{ display: isDesktopOrLaptop ? "grid" : "block", gridTemplateColumns: "repeat(3, 1fr)", gridGap: 20 }}>
          <div></div>
          <PageHeader
            icon={<img style={{ width: "100%" }} alt="Logo" src={mainImg} />}
            maintitle="Bullet Token"
            title="private sale"
          />
        </div>
        <div style={{ display: isDesktopOrLaptop ? 'flex' : 'block', width: isDesktopOrLaptop ? 'auto' : '100%', margin: isDesktopOrLaptop ? "0px auto auto auto" : "0px auto" }}>
          <StyledContainer>
            <div style={{ width: isDesktopOrLaptop ? "456px" : 'auto' }}>
              <span style={{ textAlign: 'left' }}>Participant: <b>  { (wlEnabled) ? 'Private - Whitelist' : 'Private' }</b></span>
            </div>
            <div style={{ display: 'flex' }}>
              <div className='priceState' style={{ width: isDesktopOrLaptop ? 200 : "50%" }}>
                <span>Fixed Ratio</span>
                <span className='boldFont'>1 BNB = {tokensForEth} Bullet</span>
              </div>
              <div className='priceState' style={{ width: isDesktopOrLaptop ? 200 : "50%" }}>
                <span>BNB Price</span>
                <span className='boldFont'>
                  <Value
                    value={'$' + tokenPrice}
                  />
                </span>
              </div>
            </div>
            <div style={{ display: 'flex' }}>
              <div className='priceState' style={{ margin: 'auto 0 0 auto', width: isDesktopOrLaptop ? 200 : "50%" }}>
                <span>Minimum deposit</span>
                <span className='boldFont'>{minWallet} BNB</span>
              </div>
              <div className='priceState' style={{ margin: 'auto 0 0 auto', width: isDesktopOrLaptop ? 200 : "50%" }}>
                <span>Maximum per wallet</span>
                <span className='boldFont'>{maxWallet} BNB</span>
              </div>
            </div>
            <Balances />
          </StyledContainer>
          <StyledContainerR>
            <small>Your Wallet: <b>{wallet.account ?? 'not connected'}</b></small> 
            { (wlEnabled) ? <><br /><small>{whitelisted ? <b style={{ color: "green" }}>Whitelisted</b> : <b style={{ color: "red" }}>Not Whitelisted!</b>}</small></> : ''}
            <div>
              {description}
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '8px', marginBottom: '24px' }}>
              <Countdown date={Date.now() + leftTime} />
            </div>
            <div className="borderLine" />
            <div className="bidAmount">
              <span style={{ fontSize: 15, color: 'black' }}>Your Deposit: <b>{bid} BNB</b></span>
              <div style={{ display: 'flex', color: 'black', fontSize: 15 }}>
                <span>Balance:&nbsp;</span>
                {wallet &&
                  <b>
                    <Value
                      value={new BigNumber(wallet.balance)
                        .div(new BigNumber(10).pow(18))
                        .toNumber()}
                    />
                  </b>
                }
                {!wallet &&
                  <span>--</span>
                }
                <b>&nbsp;BNB</b>
              </div>
            </div>
            <Input type='number' onChange={depositInputChange} style={{ width: '100%', bottom: 10, color: 'black', marginTop: 30, marginBottom: 0, }} placeholder='Deposit Amount' />
            <div style={{ marginTop: '50px' }}>
              <Button disabled={!account} text="Deposit" onClick={depositEther} variant="secondary" />
            </div>
          </StyledContainerR>
        </div>

        {/* <div className="backdrop"></div>
        <div className="closed">PRIVATE SALE HAS ENDED!</div> */}
      </Page>
    </div>
  )
}

const StyledContainer = styled.div`
  box-sizing: border-box;
  margin: 0px;
  max-width: 450px;
  width: 40vw;
  padding: 20px;
  position: relative;
  border: 1px solid #ccc;
  font-family: "Anton";
  margin-right: 5vw;
  background: #fdfdfd;
  @media (max-width: 767px) {
    width: auto;
    // padding: 0px;
    // left: 0;
    margin: auto;
  }
`
const StyledContainerR = styled.div`
  box-sizing: border-box;
  margin: 0px;
  max-width: 450px;
  width: 40vw;
  padding: 20px;
  position: relative;
  border: 1px solid #ccc;
  font-family: Anton;
  margin: auto 0 0 auto;
  // background: linear-gradient(108.1deg, #b84c4f, #08b200 48.54%, #08b200);
  background: #fdfdfd;
  color: black;
  font-family: "Arial";
  min-height: 400px;
  vertical-align: middle;
  @media (max-width: 767px) {
    margin-top:30px;
    padding: 48px 20px;
    width: auto;
    min-width: auto;
    margin: 40px auto;
  }
`

export default Home
