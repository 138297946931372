import Web3 from 'web3'
import BigNumber from 'bignumber.js'
import { Contracts } from './lib/contracts.js'

import { contractAddresses } from './lib/constants'

export class Presale {
  constructor(provider, networkId, options) {
    var realProvider

    if (typeof provider === 'string') {
      if (provider.includes('wss')) {
        realProvider = new Web3.providers.WebsocketProvider(
          provider,
          options.ethereumNodeTimeout || 10000,
        )
      } else {
        realProvider = new Web3.providers.HttpProvider(
          provider,
          options.ethereumNodeTimeout || 10000,
        )
      }
    } else {
      realProvider = provider
    }

    this.web3 = new Web3(realProvider)

    if (options.defaultAccount) {
      this.web3.eth.defaultAccount = options.defaultAccount
    }
    this.contracts = new Contracts(realProvider, networkId, this.web3, options)
    this.presaleAddress = contractAddresses.PresaleErc20[networkId]
    this.DepositAmount = this.getDepositAmount()
  }

  setProvider(provider, networkId) {
    this.web3.setProvider(provider)
    this.contracts.setProvider(provider, networkId)
    this.operation.setNetworkId(networkId)
  }

  async getDepositAmount() {
    const DepositAmount = await this.contracts.PresaleErc20.methods.getDepositAmount().call()
    return DepositAmount;
  }

  toBigN(a) {
    return BigNumber(a)
  }
}
